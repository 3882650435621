import React from 'react';

const Sidebar = () => {
    return (
        <div id="right-column" className="column sidebar">
            <h3>Welcome</h3>
            <p>
                Hello and welcome! I hope that this website will help people discover the beauty and richness of Japanese cuisine, and that it will become a valuable resource for anyone interested in exploring this amazing culinary tradition.
            </p>
            <a href="/recipes">
                <button className="button is-primary">View All Recipes</button>
            </a>
        </div>
    );
};

export default Sidebar;
