import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';

const HomePage = () => {
    const [recipes, setRecipes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const pageSize = 4;

    useEffect(() => {
        const fetchRecipes = async () => {
            try {
                // Fetch the page-specific index file
                const response = await fetch(`/indexes/blog/page_${currentPage}.json`);
                const data = await response.json();
                setRecipes(data);

                // Fetch the metadata to get total pages
                const metaResponse = await fetch('/indexes/blog/_meta.json');
                const metaData = await metaResponse.json();
                setTotalPages(metaData.totalPages);
            } catch (error) {
                console.error('Error fetching recipes:', error);
            }
        };

        fetchRecipes();
    }, [currentPage]);

    const updatePage = (newPage) => {
        setCurrentPage(newPage);
    }

    return (
        <div id="homepage" className="columns">

            <Helmet>
                <title>Japanese Cooking Recipes - Authentic & Easy to Follow</title>
                <meta name="description" content="Explore the art of Japanese cuisine with our authentic recipes. Easy-to-follow instructions for delicious home-cooked Japanese meals." />
                <meta name="keywords" content="Japanese cooking, Japanese recipes, authentic Japanese dishes, easy Japanese recipes, cooking Japanese food" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <div className="column is-two-thirds">
                {recipes.map(recipe => (
                    <div className="recipe-details" key={recipe.slug}>
                        <h2>{recipe.title}</h2>
                        <div className="introduction">
                            <ReactMarkdown>{recipe.introduction}</ReactMarkdown>
                        </div>
                        <Link to={`/recipes/${recipe.slug}`}>Continue Reading</Link>
                    </div>
                ))}
                
                {/* Pagination Controls */}
                <div className="pagination">
                    <button className="button is-text" onClick={() => updatePage(currentPage - 1)} disabled={currentPage <= 1}>
                        Previous
                    </button>
                    <button className="button is-text" onClick={() => updatePage(currentPage + 1)} disabled={currentPage >= totalPages}>
                        Next
                    </button>
                </div>
            </div>
            <Sidebar />
        </div>
    );
};

export default HomePage;
