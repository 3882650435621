import React from 'react';

function Title() {
  return (
    <div id="title" className="has-text-centered">
        <figure className="image is-inline-block">
            <img className="logo" src="/images/cropped-japanese-cooking-mama.webp" alt="Japanese Cooking Mama" />
        </figure>
        <div id="title-columns" className="columns">
            <div className="column">
            </div>
            <div className="column">
                <h1>Japanese Cooking</h1>
            </div>
            <div className="column">
            </div>
        </div>
    </div>
  );
}

export default Title;
