import React from 'react';
import { Helmet } from 'react-helmet';

const GoogleAnalytics = () => {
  return (
    <Helmet>
<script async src="https://www.googletagmanager.com/gtag/js?id=G-TF3K0JLMXD"></script>
<script>
  {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-TF3K0JLMXD');
  `}
</script>
    </Helmet>
  );
};

export default GoogleAnalytics;
