import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import { fetchRecipe } from '../utils/fetchRecipe';

const RecipeDetailPage = () => {
  let navigate = useNavigate();
  const { slug } = useParams();
  const [recipe, setRecipe] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const recipeData = await fetchRecipe(slug);
      setRecipe(recipeData);
      setIsLoading(false); 
    };

    fetchData();
  }, [slug]);

  // Back button handler
  const handleBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!recipe) {
    return <div>Recipe not found</div>;
  }

  const generateRecipeSchema = () => {
    if (!recipe) return;

    const schema = {
      "@context": "http://schema.org",
      "@type": "Recipe",
      "name": recipe.title,
      "description": recipe.introduction,
      "recipeIngredients": recipe.ingredients,
      "recipeInstructions": recipe.instructions,
      "datePublished": recipe.publication_date
    };

    return JSON.stringify(schema);
  };

  const formattedDate = format(new Date(recipe.publication_date), 'MMMM d, yyyy');

  return (
    <div className="recipe-details">
      <Helmet>
        <title>Japanese Cooking Recipes - Authentic & Easy to Follow</title>
        <meta name="description" content="Explore the art of Japanese cuisine with our authentic recipes. Easy-to-follow instructions for delicious home-cooked Japanese meals." />
        <meta name="keywords" content="Japanese cooking, Japanese recipes, authentic Japanese dishes, easy Japanese recipes, cooking Japanese food" />
        <script type="application/ld+json">{generateRecipeSchema()}</script>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <a className="back" onClick={handleBack}>&lt; Back</a>

      <h2>{recipe.title}</h2>
      <div className="publication-date">{formattedDate}</div>
      <p className="introduction">{recipe.introduction}</p>

      <h3>Ingredients</h3>
      <ul className="ingredients">
        {recipe.ingredients.map((ingredient, index) => (
          <li key={index}>{ingredient}</li>
        ))}
      </ul>

      <h3>Instructions</h3>
      <ol className="instructions">
        {recipe.instructions.map((instruction, index) => (
          <li key={index}>{instruction}</li>
        ))}
      </ol>
    </div>
  );
};

export default RecipeDetailPage;