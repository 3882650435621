import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const RecipeIndexPage = () => {
    const query = useQuery();
    const queryPage = Number(query.get('page')) || 1;
    const navigate = useNavigate();

    const [recipes, setRecipes] = useState([]);
    const [currentPage, setCurrentPage] = useState(queryPage);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const fetchRecipes = async () => {
            try {
                // Fetch the page-specific index file
                const response = await fetch(`/indexes/list/page_${currentPage}.json`);
                const data = await response.json();
                setRecipes(data);

                // Fetch the metadata to get total pages
                const metaResponse = await fetch('/indexes/list/_meta.json');
                const metaData = await metaResponse.json();
                setTotalPages(metaData.totalPages);
            } catch (error) {
                console.error('Error fetching recipes:', error);
            }
        };

        fetchRecipes();
    }, [currentPage]);

    const updatePage = (newPage) => {
        setCurrentPage(newPage);
        navigate(`/recipes?page=${newPage}`);
    }

    return (
        <div>
            <div className="columns">
                <div className="column is-two-thirds">
                    <h2>Recipes</h2>
                    <ul className="recipe-list">
                        {recipes.map(recipe => (
                            <li key={recipe.slug}>
                                <Link to={`/recipes/${recipe.slug}`}>{recipe.title}</Link>
                            </li>
                        ))}
                    </ul>

                    <div className="pagination">
                        <button className="button is-text" onClick={() => updatePage(currentPage - 1)} disabled={currentPage <= 1}>
                            Previous
                        </button>
                        <button className="button is-text" onClick={() => updatePage(currentPage + 1)} disabled={currentPage >= totalPages}>
                            Next
                        </button>
                    </div>
                </div>
                <Sidebar />
            </div>
        </div>
    );
};

export default RecipeIndexPage;
