// fetchRecipe.js
export const fetchRecipe = async (slug) => {
    try {
      const jsonFileUrl = `/recipes/${slug}.json`;
      const response = await fetch(jsonFileUrl);
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      let recipeData = await response.json();
  
      // Check if ingredients exist and are in string format
      if (recipeData.ingredients && typeof recipeData.ingredients === 'string') {
        recipeData = {
          ...recipeData,
          // Split the string into an array, trim whitespace, and remove leading asterisks
          ingredients: recipeData.ingredients
            .split('\n')
            .map(ingredient => ingredient.trim())
            .map(ingredient => ingredient.replace(/^\*/, ''))
        };
      }

      // Do the same for the instructions
      if (recipeData.instructions && typeof recipeData.instructions === 'string') {
        recipeData = {
          ...recipeData,
          instructions: recipeData.instructions
            .split('\n')
            .map(instruction => instruction.trim())
            .map(instruction => instruction.replace(/^\d+\./, ''))
            .map(instruction => instruction.replace(/^\*/, ''))
        };
      }
  
      return recipeData;
    } catch (error) {
      console.error('Error fetching recipe:', error);
      throw error; // Re-throw the error to handle it in the component
    }
  };
  