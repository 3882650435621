import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Title from './components/Title';
import Footer from './components/Footer';

import HomePage from './pages/HomePage';
import RecipeIndexPage from './pages/RecipeIndexPage';
import RecipeDetailPage from './pages/RecipeDetailPage';
import GoogleAnalytics from './components/GoogleAnalytics';

const App = () => {
  return (
    <Router>
      <GoogleAnalytics />
      <section id="page-section" className="section">
        <div className="container is-max-desktop">
          <div className="App">
            <Header />
            <Title />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/recipes" element={<RecipeIndexPage />} />
              <Route path="/recipes/:slug" element={<RecipeDetailPage />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </section>
    </Router>
  );
};

export default App;
